import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

window.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('body.controller--top')) return false

  // PC版のスライダー設定
  let pcSlider = $('.pc_slick-slider').slick({
    arrows: true,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    dots: true,
    infinite: true,
    slidesToShow: getSlidesCount(),
    slidesToScroll: getSlidesCount(),
    appendDots: '.slick-dots',
    appendArrows: '.slick-arrows',
    prevArrow: '<button class="slick-left"><i class="fa fa-chevron-left"></i></button>',
    nextArrow: '<button class="slick-right"><i class="fa fa-chevron-right"></i></button>'
  })

  // SP版のスライダー設定
  $('.sp_slick-slider').slick({
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
  })
  // 再生成後に再度クリックイベントを設定
  $('.pc_slick-slider').on('click', 'a', function (event) {
    event.stopPropagation(); // クリックイベントの伝播を停止
    window.location.href = $(this).attr('href'); // リンク先に移動
  });

  function getSlidesCount() {
    if (window.innerWidth >= 1280) {
      return 4
    } else if (window.innerWidth >= 1080) {
      return 3
    } else if (window.innerWidth >= 768) {
      return 2
    } else if (window.innerWidth < 768) {
      return 3
    } else {
      return 1
    }
  }

  // 画面サイズの変更を監視
  window.addEventListener('resize', () => {
    const slidesCount = getSlidesCount();
    pcSlider.slick('unslick'); // スライダーを再生成するため、一度削除
    pcSlider = $('.pc_slick-slider').slick({
      arrows: true,
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      dots: true,
      infinite: true,
      slidesToShow: slidesCount,
      slidesToScroll: slidesCount,
      appendDots: '.slick-dots',
      appendArrows: '.slick-arrows',
      prevArrow: '<button class="slick-left"><i class="fa fa-chevron-left"></i></button>',
      nextArrow: '<button class="slick-right"><i class="fa fa-chevron-right"></i></button>'
    });

    // 再生成後に再度クリックイベントを設定
    $('.pc_slick-slider').on('click', 'a', function (event) {
      event.stopPropagation(); // クリックイベントの伝播を停止
      window.location.href = $(this).attr('href'); // リンク先に移動
    });
  })

  $('.front-feature-example.sp').slick({
    arrows: false,
    adaptiveHeight: false,
    autoplay: false,
    dots: false,
    infinite: false,
    centerMode: true,
    centerPadding: '5px',
    slidesToShow: 1.2,
  })
})
